
import { Button } from 'react-bootstrap';
import Slides from '../components/Slides';
import { useSearchParams } from 'react-router-dom';
import {projects} from '../constants/projectsList';
function Project() {
    const [searchParams] = useSearchParams();

    const project = findArrayElementByTitle(projects, searchParams.get("name"));

    function findArrayElementByTitle(array, title) {
        return array.find((element) => {
          return element.name === title;
        })
      }

    const handleClick = () => {
        window.open(project.url, '_blank')
    }
    return(
        <div className='project'>
            <h2>{project?.name}</h2>
            
            <div className='carousel'>
                <Slides slides={project.images}/>
            </div>

            <p className='line-break'>{project.description}</p>
            {project.url && <Button onClick={handleClick} className='button-primary'>{project.urlType}</Button>}
        </div>
    )
}

export default Project;