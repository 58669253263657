import ProjectCard from "../cards/ProjectCard";
import { projects } from "../constants/projectsList";
function Projects() {
    return (
        <div className="section">
            <h2 className="title">My Projects</h2>
            <div className="projects">
                {projects.map((project, index) => {
                    return <ProjectCard key={index} project={project}/>
                })}     
            </div>
            
        </div>
    )
}

export default Projects;