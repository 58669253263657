import { Button, Image } from "react-bootstrap";
import { createSearchParams, useNavigate } from "react-router-dom";

function ProjectCard(props) {
    const navigate = useNavigate();
    const project = props.project;
    const params = {name: project.name};

    const handleClick = () => {
        navigate({pathname: "/project", search: `${createSearchParams(params)}` }, {state: project});
    }
    return (
        <div className="project-card">
            {project.thumbnail && <Image src={require('../img/' + project.thumbnail)}/>}
            <h4>{project.name}</h4>
            <div className="project-details">
                <p>{project.summary}</p>
                <Button onClick={handleClick} style={{marginBottom:"10px"}} className='button-primary'  variant="primary" type="submit">
                    Read more
                </Button>
            </div>
            
        </div>
    )
}

export default ProjectCard;