import { Button, Image } from "react-bootstrap";
import photo from "../img/fatima-photo.jpeg";
import linkedinLogo from "../icons/linkedin.png";
import githubLogo from "../icons/github1.png";

function Main() {
    const goToGithub = () => {
        window.open('https://github.com/fatima-albaiti', '_blank')
    }

    const goToLinkedIn = () => {
        window.open('https://linkedin.com/in/fatima-albaiti', '_blank');
    }

    return (
        <div className="section main">
            <div className="left">
                <h1 className="main title">Fatima Al-Baiti</h1>
                <p>Full Stack Web/Android Developer</p>
                <div>
                    <Image onClick={goToLinkedIn} className="button-social" src={linkedinLogo} />
                    <Image onClick={goToGithub} className="button-social" src={githubLogo} />
                </div>
                
                <a href={"#contact"}><Button className="button-primary" variant="primary">Contact me</Button></a>

                
            </div>
            <div className="right">
                <Image className="main-image" src={photo} thumbnail />
            </div>
            
        </div>
    )
}

export default Main;