import Skill from "../cards/Skill";
import {skills} from '../constants/skillsList';

function Skills() {
    // const skills = [
    //     {type: "Frontend", skillsList: ["React", "CSS", "HTML", "Boostrap"]},
    //     {type: "Backend", skillsList: ["Node.js", "SQL", "MongoDB"]},
    //     {type: "Android", skillsList: ["Kotlin", "Java", "Android Studio"]}
    // ];
    return (
        <div className="section">
            
            <h2 className="title">My Skills</h2>
            <div className="skills">
                
                {skills?.map((skill, index) => {
                    return <Skill key={index} skill={skill.skills} skillType={skill.category}/>
                })}
            </div>
            
        </div>
    )
}

export default Skills;