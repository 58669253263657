import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e) => e.preventDefault();

const Slides = (props) =>{ 
    const slides = props.slides;

    const items = slides?.map(slide => 
        {
            return slide.type === "image" ?
            <div className='item'>
                <img className='media' src={require('../img/' + slide.url)} onDragStart={handleDragStart} role="presentation" />    
            </div>
        :
            <div className='item'>
                <video className='media' width="100%" controls  onDragStart={handleDragStart} role="presentation" >
                    <source src={require('../video/' + slide.url)} />
                    Your browser does not support the video tag.
                </video>   
            </div>
        }
    )
    
    return( 
        <AliceCarousel 
            items={items} 
            renderPrevButton={() => {
                return <div className="btn-prev">&lang;</div>
            }}
            renderNextButton={() => {
                return <div className="btn-next" >&rang;</div>
            }}/>    
    );
}
export default Slides;