
function Skill(props) {
    const skills = props.skill;
    const skillType = props.skillType;
    return (
        <div className="skill">
            <h3>{skillType}</h3>
            <hr/>
            <ul>
                {skills.map((skill, index) => {
                    return <li key={index}>
                        <img className="skill-icon" src={require('../icons/' + skill.icon)} />
                        {skill.name}
                        </li>
                })}
            </ul>
        </div>
    )
}

export default Skill;