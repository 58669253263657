import Main from "../sections/Main";
import Skills from "../sections/Skills";
import Projects from "../sections/Projects";
import Contact from "../sections/Contact";

function Home() {
    return (
        <>
        <Main />
        <Skills />
        <Projects />
        <Contact />
        </>
    )
}

export default Home;