export const skills = [
        {category: "Frontend", skills: [
            {name: "React", icon: "react.png"},
            {name: "CSS", icon: "css.png"},
            {name: "HTML", icon: "html.png"},
            {name: "Bootstrap", icon: "bootstrap.png"}
        ]},
        {category: "Backend", skills: [
            {name: "Node.js", icon: "nodejs.png"},
            {name: "SQL", icon: "sql.png"},
            {name: "PHP", icon: "php.png"},
            {name: "Postman", icon: "postman.png"}
        ]},
        {category: "Android", skills: [
            {name: "Android Studio", icon: "android-studio.png"},
            {name: "Java", icon: "java.png"},
            {name: "Kotlin", icon: "kotlin.png"},
            {name: "Retrofit", icon: "retrofit.png"}
        ]}
    
]