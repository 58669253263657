export const projects = [
    {name: "Ameca Robotics - ADNOC Oasis Robot", urlType: "Instagram", 
    url: "https://www.instagram.com/amecarobotics?igsh=N2lidDl1MjhpNjNp", 
    category: "mobile", 
    thumbnail: "robot-thumbnail.png", summary:"Android | Java | ChatGPT API | Google ML Kit | Tensorflow ", 
    images: [{url: "robot.jpeg", type: "image"}, {url: "robot2.jpeg", type: "image"}, {url: "navi.mp4", type: "video"}, {url: "greeting.mp4", type: "video"}, {url: "robot-chat.mp4", type: "video"}],
    description: `In this project, I led the design and development of Android applications specifically tailored for interfacing with and controlling robotic systems. Collaborating closely with the engineering team, I ensured seamless integration of software functionalities with robotic hardware systems, guaranteeing optimal performance and reliability in real-world applications.

    The Android applications boasted advanced features aimed at enhancing user interaction and control capabilities. Notable functionalities included:
    
    1. Chat Feature: I integrated Azure Speech to Text and Text to Speech services to enable real-time chat interactions with the robot. To provide intelligent AI responses, I leveraged ChatGPT, ensuring support for multilingual conversations in both English and Arabic.
    
    2. Navigation System: I engineered a robust navigation interface utilizing SLAM (Simultaneous Localization and Mapping) technology. The application featured an intuitive map displaying the environment, enabling users to set destinations by simply pointing on the map. Additionally, voice commands were integrated for seamless control and navigation.
    
    3. Greeting System: Leveraging Google ML Kit's face detection capabilities, I implemented a personalized greeting system. Upon detecting a user's presence through face recognition, the robot greeted the user in both English and Arabic. Furthermore, the system displayed current offers at the ADNOC Oasis, enriching user engagement and experience.
    
    Throughout the development process, I prioritized user-centric design and functionality, ensuring that the applications provided intuitive interfaces and seamless interactions. By incorporating cutting-edge technologies and innovative features, the Android applications offered enhanced control and interaction capabilities with robotic systems, catering to diverse user needs and preferences.`},

    {name: "Space Noob - Personal Project", urlType: "Website", url: "https://space-noob.com", 
    category: "website", thumbnail: "space-noob-thumbnail.jpg", summary:"Website | React | NodeJS", 
    images: [{url: "space-noob1.png", type: "image"}, {url: "space-noob2.png", type: "image"},
    {url: "space-noob3.png", type: "image"}, {url: "space-noob4.png", type: "image"},
    {url: "space-noob5.png", type: "image"}, {url: "space-noob6.png", type: "image"},
    {url: "space-noob7.png", type: "image"}, {url: "space-noob8.png", type: "image"}],
    description: `In this project, I employed React.js for frontend development and Node.js for backend development, ensuring a cohesive and efficient web application ecosystem. Leveraging the power of React.js, I crafted dynamic and responsive user interfaces, while Node.js facilitated robust backend functionality.

    To ensure efficient data storage and retrieval, I integrated Cloud Firestore, a scalable NoSQL database provided by Google Cloud Platform. This allowed for seamless management of application data, enhancing performance and scalability.
    
    One of the key features of the application was the dynamic article display, achieved through custom APIs. To provide users with a seamless browsing experience, I incorporated loading spinners to indicate ongoing data retrieval processes, enhancing user engagement and satisfaction.
    
    Real-time moon phase updates were made possible through integration with an external weather API, enabling users to stay informed with accurate and up-to-date information.
    
    Furthermore, I implemented a contact form with Google reCAPTCHA integration to enhance security and prevent spam submissions. This ensured that user interactions with the contact form were legitimate and secure. Additionally, email functionality was integrated to enable seamless communication between users and the website owner.
    
    To streamline deployment processes and ensure efficient updates, I automated deployment using GitHub Actions. This automated workflow facilitated continuous integration and deployment, allowing for rapid iteration and updates to the application without manual intervention.`},

    
    {name: "Faras App", urlType: "Github", url: "", category: "mobile", 
    thumbnail: "faras-logo.jpeg", summary:"Android | Kotlin | REST API | Retrofit ", 
    images: [{url: "faras1.png", type: "image"}, {url: "faras2.png", type: "image"},
    {url: "faras3.png", type: "image"}, {url: "faras4.png", type: "image"},
    {url: "faras5.png", type: "image"}, {url: "faras6.png", type: "image"},
    {url: "faras7.png", type: "image"}, {url: "faras8.png", type: "image"}],
    description: `In this project, I spearheaded the development and successful launch of a comprehensive Android application available on the Play Store, aimed at offering a diverse range of vehicle services, from insurance procurement to mobile car wash solutions. Employing the Model-View-ViewModel (MVVM) architecture, I ensured the application's scalability and maintainability. 
    
    Key functionalities included enabling users to seamlessly receive insurance quotes from multiple companies within the app, facilitating informed decision-making. Additionally, I designed and integrated features allowing users to efficiently manage multiple vehicles within their accounts for seamless tracking and organization. Leveraging advanced technologies such as Google ML Kit and camera integration, I incorporated optical character recognition (OCR) technology, empowering users to effortlessly update registration details and Emirates ID. Integrating Representational State Transfer (REST) APIs facilitated smooth retrieval of user information, insurance quotes, and car wash shop listings, enhancing the overall user experience. 
    
    Furthermore, I prioritized security by implementing one-time passwords (OTP) sent to mobile numbers and email addresses during account creation. By collaborating closely with cross-functional teams throughout the development lifecycle, I ensured the resolution of bugs and maintained a professional and detail-oriented approach, ultimately delivering high-quality outcomes and ensuring user satisfaction in every aspect of the app development process.`},
    
    {name: "Frontier Learning Solutions", urlType: "Instagram", url: "https://www.instagram.com/frontier.ls?igsh=MW04eTZidWlkemJ6NA==", 
    category: "website", thumbnail: "frontier-logo.png", summary:"Website | PHP | Yii2 | SQL", 
    images: [{url: "frontier-ls1.jpeg", type: "image"}, {url: "frontier-ls2.jpeg", type: "image"},
    {url: "frontier-ls3.jpeg", type: "image"}, {url: "frontier-ls4.jpeg", type: "image"},
    {url: "frontier-ls5.jpeg", type: "image"}, {url: "frontier-ls6.jpeg", type: "image"},
    {url: "frontier-ls7.jpeg", type: "image"}, {url: "frontier-ls8.jpeg", type: "image"},
    {url: "frontier-ls9.jpeg", type: "image"}],
    description: `In this project, I orchestrated the development of a dynamic website using PHP in conjunction with the Yii2 framework, offering users seamless browsing capabilities to explore various camps and enroll in them conveniently. Integration of online payment gateways such as Telr and PayPal facilitated hassle-free transactions, enhancing the user experience. Additionally, I implemented a feature-rich content slider enabling users to engage with diverse types of multimedia content.
   
    One of the key highlights of the website was the incorporation of a leaderboard system, intelligently tallying students' scores and presenting them in descending order of achievement. This gamified element not only fostered healthy competition but also incentivized active participation.
    
    Furthermore, I designed and built a robust store section where users could utilize their accumulated points, earned throughout the camp, to purchase items at discounted rates. Leveraging MySQL, I constructed a scalable backend infrastructure, ensuring seamless data management and storage to accommodate the website's growing user base.
    
    The website quickly gained traction, attracting over 3,000 users and hundreds of students within its first year of operation. Notably, it achieved financial sustainability, breaking even within the initial twelve months of launch. This success underscored the effectiveness of the platform in meeting user needs and fulfilling its intended objectives.`},
    
    
]