import './App.css';
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home';
import Project from './pages/Project';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ScrollToTop />
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/project' element={<Project />} />
        </Routes>
      </header>
    </div>
  );
}

export default App;
